import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: '',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
        path: '/protocolo',
        title: 'Novo Protocolo',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-add',
        submenu: []
    },
    /* 
    {
        path: '/pre-cadastro',
        title: 'Pré-cadastro',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'inbox',
        submenu: []
    },   
    */ 
    {
        path: '/consulta',
        title: 'Consulta',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'file-search',
        submenu: []
    }
]    