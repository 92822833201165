import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { seguranca_redirect_url_old, cs_organizacional_url } from "../constantes";
import { catchError } from "rxjs/operators";
import { UsuarioAutenticadoService } from "../services/usuario-autenticado.service";
import { NzMessageService, NzModalService } from "ng-zorro-antd";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private usuarioService: UsuarioAutenticadoService,
    private modalService: NzModalService,
    private msg: NzMessageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clone = req; 

    if(req.url.includes(cs_organizacional_url)) {
      clone = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.usuarioService.getCookieValue()}`)
      });
    }

    return next.handle(clone).pipe(
        catchError((error: HttpErrorResponse) => {
            if(error) {
              if(error.status === 401) {
                this.modalService.error({
                  nzTitle: 'Acesso negado',
                  nzContent: 'Usuário sem acesso ao Protocolo Online.',
                  nzOnOk: () => {
                    window.location.href = seguranca_redirect_url_old;
                  },
                });
              }

              if(error.status == 403) {
                this.msg.error('Você não possui acesso a esse recurso.');
              }

              if(error.status == 500) {
                // this.msg.error('Ops! Houve um erro inesperado.')
                this.msg.error(error.error);
              }

              if(error.status == 504) {
                this.msg.error('Que pena! Estamos enfrentando instabilidades em nosso serviço. Por favor tente novamente em alguns instantes.');
              }
            }

            return throwError(error);
        })
    );
  }

}