import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'pre-cadastro',
        data: {
            title: 'Pré Cadastro'
        },
        loadChildren: () => import('../../pre-cadastro/pre-cadastro.module').then(m => m.PreCadastroModule)
    },
    {
        path: 'consulta',
        data: {
            title: 'Consulta'
        },
        loadChildren: () => import('../../consulta/consulta.module').then(m => m.ConsultaModule)
    },
    {
        path: 'protocolo',
        data: {
            title: 'Protocolos'
        },
        children: [
            {
                path: '',
                redirectTo: '/protocolo',
                pathMatch: 'full'
            },
            {
                path: '',
                loadChildren: () => import('../../protocolo/protocolo.module').then(m => m.ProtocoloModule)
            },
        ]
    }
 
];