import { Component } from '@angular/core';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { UsuarioAutenticadoService } from '../../services/usuario-autenticado.service';
import { OrganizacaoService } from '../../services/organizacao/organizacao.service';
import { SetorService } from '../../services/setor/setor.service';
import { tap, filter, switchMap } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OabSegurancaService } from '../../services/seguranca/oab-seguranca.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent {

    searchVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;
    avatar: string;

    isModalVisible = false;
    isAlertVisible = false;
    isModalLoading = false;
    isLoading = false;

    organizacoesLoading = true;
    setoresLoading = false;
    okLoading = false;

    usuarioAutenticado$ = this.usuarioService.getUsuarioAutenticado();

    trocarSetorForm = new FormGroup({
        codigoOrganizacao: new FormControl(null, Validators.required),
        codigoSetor: new FormControl(null, Validators.required),
    });

    organizacoes$ = this.organizacoesService.listarOrganizacoesAcesso()
    .pipe(
        tap(() => {
            this.organizacoesLoading = false;
        })
    );

    setores$ = this.trocarSetorForm.get('codigoOrganizacao').valueChanges
    .pipe(
        filter(id => id !== null),
        tap(() => {
            this.trocarSetorForm.patchValue({ codigoSetor: null });
            this.setoresLoading = true;
        }),
        switchMap(id => this.setorService.listarSetoresByOrganizacao(id).pipe(
            tap(() => this.setoresLoading = false)
        ))
    );

    constructor( 
        private themeService: ThemeConstantService,
        private usuarioService: UsuarioAutenticadoService,
        private organizacoesService: OrganizacaoService,
        private setorService: SetorService,
        private segurancaService: OabSegurancaService,
        private modal: NzModalService
    ) {}

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    logout() {
        this.usuarioService.logout();
    }

    trocarSetor() {
        this.usuarioService.trocarSetor();
    }

    showModal(index: number) {
        this.isModalVisible = true;
    }
    
    handleCancel() {
        this.isModalVisible = false;
    }

    handleOk() {
        this.isModalLoading = true;
        
        if(this.trocarSetorForm.invalid){
            this.isModalLoading = false;
            return;
        }

        const newOrgPadrao = parseInt(this.trocarSetorForm.get('codigoOrganizacao').value, 10);
        const newSetorPadrao = parseInt(this.trocarSetorForm.get('codigoSetor').value, 10);
        const oldOrgPadrao = this.segurancaService.getOrganizacaoPadrao();
        const oldSetorPadrao = this.segurancaService.getSetorPadrao();

        console.log('ORganizacao.old: '+oldOrgPadrao);
        console.log('Setor.old: '+oldSetorPadrao);

        console.log('ORganizacao.new: '+newOrgPadrao);
        console.log('Setor.new: '+newSetorPadrao);

        if ((oldOrgPadrao === newOrgPadrao && oldSetorPadrao !== newSetorPadrao) || (oldOrgPadrao !== newOrgPadrao) ) {
            this.segurancaService.setOrganizacaoPadrao(newOrgPadrao);
            this.segurancaService.setSetorPadrao(newSetorPadrao);

            this.isModalVisible = false;
            location.reload();
        } else {
            this.isModalVisible = false;
        }
    }

}
