export const oab_access_token = 'oab-access-token';

export const oab_organizacao_padrao = 'oab-organizacao-padrao';

export const oab_organizacao_padrao_hom = 'oab-organizacao-padrao-hom';

export const oab_setor_padrao = 'oab-setor-padrao';

export const oab_setor_padrao_hom = 'oab-setor-padrao-hom';

export const SEGURANCA_OPTIONS = 'OabSegurancaOptions';