import { Injectable, Optional } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UsuarioAutenticado } from "../models/usuario-autenticado.model";
import { environment } from "src/environments/environment";
import { CookieService } from "ngx-cookie-service";
import * as jwt_decode from 'jwt-decode';
import { map, shareReplay } from "rxjs/operators";
import { cs_organizacional_url, seguranca_redirect_url_old, seguranca_redirect_muda_setor_url} from "../constantes";


@Injectable({
    providedIn: "root"
})
export class UsuarioAutenticadoService {

    private serviceUrl = `${environment.baseUrl}/autenticacao`;

    foto: Observable<string>;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService) {}

    getUsuarioAutenticado(): Observable<UsuarioAutenticado> {
        return this.http.get<UsuarioAutenticado>(this.serviceUrl)
        .pipe(shareReplay(1));
    }

    getFoto(): Observable<string> {
        if(this.foto == null) {
            let idenMembro = jwt_decode(this.getCookieValue())['iden_membro'];
            this.foto = this.http.get<string>(`${cs_organizacional_url}/${idenMembro}`)
                .pipe(
                    map(res => `data:image/png;base64,${res['data']}`)
                );
        }

        return this.foto;
    }

    isAuthenticated(): boolean {        
        if(!this.cookieService.check(this.getCookieName())) {
            window.location.href = seguranca_redirect_url_old;
            return false;
        }

        return true;
    }

    logout(): void {
        this.cookieService.deleteAll('/', 'oab.org.br');
        window.location.href = seguranca_redirect_url_old;
    }

    public getCookieValue(): string {
        return this.cookieService.get(this.getCookieName());
    }

    private getCookieName(): string {
        return environment.production ? ".CFOAB_AUTH" : ".CFOAB_AUTH_DEV";
    }

    trocarSetor(): void {
        // this.clearStoredAuthentication(); // https://seguranca-hom.oab.org.br/pages/login?callbackUrl=aHR0cDovL2xvY2FsaG9zdC5vYWIub3JnLmJyOjQyMDAvcHJvdG9jb2xvLzQ5LjAwMDAuMjAyMS4wMDAwMDYtMA%3D%3D
        window.location.href = seguranca_redirect_muda_setor_url;
    }

}