import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { oab_organizacao_padrao, oab_setor_padrao, SEGURANCA_OPTIONS, oab_organizacao_padrao_hom, oab_setor_padrao_hom } from '../../oab-seguranca.constants';
import { OabSegurancaOptions } from '../../models/oab-seguranca.model';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class OabSegurancaService {

  constructor(
    private cookieService: CookieService,
    private http: HttpClient
  ) { }

  public getOrganizacaoPadrao(): number {
    if (environment.production) {
      return parseInt(this.cookieService.get(oab_organizacao_padrao), 10);
    }else{
      return parseInt(this.cookieService.get(oab_organizacao_padrao_hom), 10);
    }
  }

  public getSetorPadrao(): number {
    if (environment.production) {
        return parseInt(this.cookieService.get(oab_setor_padrao), 10);
    } else {
        return parseInt(this.cookieService.get(oab_setor_padrao_hom), 10);
    }
    return 0;
  }

  public setOrganizacaoPadrao(id: number) {
    if(environment.production) {
      this.cookieService.set(oab_organizacao_padrao, String(id), null, null, '.oab.org.br');
    } else {
      this.cookieService.set(oab_organizacao_padrao_hom, String(id), null, null, '.oab.org.br');
    }
  }

  public setSetorPadrao(id: number) {
    if(environment.production) {
      this.cookieService.set(oab_setor_padrao, String(id), null, null, '.oab.org.br');
    } else {
      this.cookieService.set(oab_setor_padrao_hom, String(id), null, null, '.oab.org.br');
    }
  }
}
