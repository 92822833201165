import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Organizacao } from "../../models/organizacao-model";
import { HttpClient } from "@angular/common/http";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class OrganizacaoService {

    private serviceUrl = "api/protocolo-online/v1/organizacoes";

    constructor(private http: HttpClient) {}

    listar(): Observable<Organizacao[]> {
        return this.http.get<Organizacao[]>(this.serviceUrl)
        .pipe(shareReplay(1));
    }

    listarOrganizacoesAcesso(): Observable<Organizacao[]> {
        return this.http.get<Organizacao[]>("api/protocolo-online/v1/organizacoes/listar-organizacoes-acesso")
        .pipe(shareReplay(1));
    }

    listarOrganizacoesPrincipais(): Observable<Organizacao[]> {
        return this.http.get<Organizacao[]>("api/protocolo-online/v1/organizacoes/principais")
        .pipe(shareReplay(1));
    }

}