import { Component, OnDestroy, OnInit } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent implements OnInit, OnDestroy {

    private componentActive = true;

    public menuItems: any[]
    isFolded : boolean;
    isSideNavDark : boolean;

    constructor( private themeService: ThemeConstantService) {}

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);

        this.themeService.isMenuFoldedChanges
            .pipe(takeWhile(() => this.componentActive))
            .subscribe(isFolded => this.isFolded = isFolded);
            
        this.themeService.isSideNavDarkChanges
            .pipe(takeWhile(() => this.componentActive))
            .subscribe(isDark => this.isSideNavDark = isDark);
    }

    ngOnDestroy() {
        this.componentActive = false;
    }

}
