import { environment } from "src/environments/environment";

export const api_secao = 'api/secao';
export const api_tipo_secao = 'api/tipo-secao';

export const seguranca_redirect_url_new = `${environment.production ? 'https':'http'}://${environment.production ? 'seguranca':'seguranca-hom'}.oab.org.br/pages/login?callbackUrl=${btoa(window.location.href)}`;
export const cs_organizacional_url = `${environment.production ? 'https':'http'}://cs-organizacional${environment.production ? '':'-hom'}.oab.org.br/api/membro/photo`;

export const codigo_organizacao_conselho_federal = 49;
export const codigo_setor_protocolo_conselho_federal = 1852;

export const seguranca_redirect_url_old = `https://${environment.production ? 'www1' : 'homnet'}.oab.org.br/Login/?aplicacao=53&ReturnUrl=${window.location.href}`;
export const seguranca_redirect_muda_setor_url = `https://${environment.production ? 'www1' : 'homnet'}.oab.org.br/login/changedepartment.aspx?redirect=${window.location.href}`;