import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Setor } from "../../models/setor.model";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class SetorService {
    
    private serviceUrl = "api/protocolo-online/v1/setor";
    
    constructor(private http: HttpClient) {}

    recuperarSetor(idenOgan: number, codiSetr: number): Observable<any>{
        return this.http.get(`${this.serviceUrl}/${idenOgan}/${codiSetr}`)
        .pipe(shareReplay(1));
    }

    listarSetoresPorOrganizacao(idenOgan: number): Observable<Setor[]> {
        return this.http.get<Setor[]>(`${this.serviceUrl}/${idenOgan}`);
    }

    // sem validacao no end-point de grupo biro.
    listarSetoresByOrganizacao(idenOgan: number): Observable<Setor[]> {
        return this.http.get<Setor[]>(`${this.serviceUrl}/listar-setores/${idenOgan}`);
    }

    listarSetoresComRemessaByOrganizacaoUsuarioLogado(): Observable<Setor[]> {
        return this.http.get<Setor[]>(`${this.serviceUrl}/com-remessa`);
    }

    verificarUsuarioAtivoSetor(codigoOrganizacao: number, codigoSetor: number): Observable<Setor>{
        return this.http.get<Setor>(`${this.serviceUrl}/com-usuario-ativo/${codigoOrganizacao}/${codigoSetor}`);
    }

}