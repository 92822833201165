import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { HighlightModule } from 'ngx-highlightjs';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SearchPipe } from './pipes/search.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { IconFilterPipe } from './pipes/iconFilter.pipe';
import { AppNgZorroAntdModule } from '../nz-zorro-antd.module';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        PerfectScrollbarModule,
        HighlightModule,
        AppNgZorroAntdModule,
        SearchPipe
    ],
    imports: [
        RouterModule,
        CommonModule,
        HighlightModule.forRoot({ theme: 'atom-one-light'}),
        PerfectScrollbarModule,
        AppNgZorroAntdModule
    ],
    declarations: [
        SearchPipe,
        DateFormatPipe,
        IconFilterPipe
    ],
    providers: []
})

export class SharedModule { }
